<template>
  <div class="section">
    <cust-header
      class="header"
      :data="contactsDetail"
      :img-url="imgUrl"
      :diminish="true"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="140"
      :need-btn="needBtn"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <contacts-info ref="contactsInfo" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="btnAid.includes('edit')"
        class="btn"
        @click="toEditContact"
      >
        编辑
      </cube-button>
    </div>
  </div>
</template>

<script >
import tabComposite from '_c/tab-composite';
import custHeader from '_c/cust-header';
import contactsInfo from '@/views/contacts/components/contacts-info';
import { custContactsDetail } from '_api/contact';
import btnAuth from '@/mixins/btnAuth';
import { mapGetters } from 'vuex';

export default {
  name: 'ContactsDetails',
  components: {
    tabComposite,
    custHeader,
    contactsInfo
  },
  mixins: [btnAuth],
  data() {
    return {
      contactsDetail: {},
      contactsData: {},
      tabList: [{
        label: '联系人信息',
        value: 0
      }],
      sellUser: {},
      index: 0,
      loadEnable: false,
      imgUrl: require('../../assets/img/person.png')
    };
  },
  computed: {
    ...mapGetters('contacts', ['aidList']),
    ...mapGetters(['dictList']),
    needBtn() {
      if (this.btnAid.includes('edit')) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    this.getAuthBtn(this.$route.params.type);
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      custContactsDetail(this.$route.params.contactId).then((res) => {
        if (res.flag) {
          // 头部信息
          let contactsDetail = res.data;
          this.contactsDetail = {
            title: contactsDetail.name,
            mainList: [
              `${contactsDetail.position ? contactsDetail.position : ''}`,
              `${contactsDetail.custCn ? contactsDetail.custCn : ''}`
            ]
          };
          // 联系人信息
          this.contactsData = res.data;
          if (this.$refs.contactsInfo) this.$refs.contactsInfo.getData(res.data);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    toEditContact() {
      this.$router.push(
        {
          name: 'addedit-contact',
          query: {
            contactId: this.$route.params.contactId
          }
        }
      );
    }
  }
};
</script>

<style  lang="scss"  scoped>
@import '@/assets/css/components/btn-bottom.scss';
.header{
  width:100%;
  height:140px;
}
</style>
