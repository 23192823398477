import { baseRequest } from './index';

/**
 * 新增联系人
 * @params data
 * @returns {*}
 */
export const addContact = data => baseRequest.post('/crmapp/custcontacts/save', data);

/**
 * 联系人列表
 * @params data
 * @returns {*}
 */
export const custContactsAlllist = (data, aid) => baseRequest.post('/crmapp/custcontacts/allList', data, {
  headers: {
    'aid': aid
  }
});

/**
 * 联系人详情
 * @params data
 * @returns {*}
 */
export const custContactsDetail = (id) => baseRequest.post('/crmapp/custcontacts/detail', {id});

/**
 * 联系人保存
 * @params data
 * @returns {*}
 */
export const custContactsSave = (data) => baseRequest.post('/crmapp/custcontacts/save', data);

/**
 * 合同消息提示详情接口
 * @params data
 * @returns {*}
 */
export const getContractWithWxContactByContId = (contId) => baseRequest.post('/crmapp/busContract/getContractWithWxContactByContId', {contId});

/**
 * 分成明细详情
 * @params data
 * @returns {*}
 */
 export const commissionShareDetail = (id, confirmId) => baseRequest.post('/crmapp/commissionShare/detail', {id, confirmId});

 /**
 * 分成确认接口
 * @params data
 * @returns {*}
 */
  export const commissionShareConfirm = (id, confirmId, confirmResult) => baseRequest.post('/crmapp/commissionShare/confirm', {id, confirmId, confirmResult});
